import Model from "../jrdm-mc/Model"

export default class CalendarEvent extends Model {
  static route = "profile/calendar-events"

  start_at: string | null = null
  end_at: string | null = null
  summary: string | null = null
  visit_id: number | null = null
}
