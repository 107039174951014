

































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { format } from "date-fns"

import { translate, getEnumValues } from "@/store"

import Visit, { VisitStatus } from "~/models/Visit"
import Partner from "~/models/Partner"
import Profile from "~/models/Profile"
import VisitsColl from "~/collections/Visits"
import Agent from "~/models/Agent"
import House from "~/models/House"
import snackbar from "~/plugins/snackbar"
import EventBus from "~/utils/EventBus"
import Column from "@/components/visits/Board/Columns/Column.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import TimeIntervalSelection from "~/components/Inputs/TimeIntervalSelection.vue"
import GoogleAddressAutocomplete, {
  Address,
} from "~/components/Inputs/GoogleAddressAutocomplete.vue"
import AgentSelect from "~/components/Inputs/AgentSelect/AgentSelect.vue"
import PromptCancelVisit from "@/components/modals/PromptCancelVisit.vue"
import VisitSurveyModal from "@/components/modals/VisitSurveyModal.vue"
import VisitGuestSelect from "~/components/Inputs/VisitGuestSelect.vue"
import OcularAvatar from "~/components/OcularAvatar.vue"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import VisitCalendar from "@/components/visits/Board/Columns/VisitCalendar.vue"
import PromptIgnorePendingForm from "@/components/modals/PromptIgnorePendingForm.vue"
import { Route } from "vue-router"
import { state } from "@/store"

@Component({
  components: {
    PromptCancelVisit,
    VisitSurveyModal,
    AgentSelect,
    GoogleAddressAutocomplete,
    TimeIntervalSelection,
    Column,
    OcularIcon,
    VisitGuestSelect,
    OcularAvatar,
    AgentItem,
    VisitCalendar,
    PromptIgnorePendingForm,
  },
})
export default class VisitDetails extends Vue {
  @Prop({ required: false, default: null }) house!: House | null
  loading = true
  visit: Visit | null = null
  visits = new VisitsColl()
  currentCalendarDate = ""
  form: Visit = new Visit()
  interval = 15
  valid = false
  gotPendingChange = false

  get visitId() {
    if (this.$route.params.visitId) return parseInt(this.$route.params.visitId)
    return null
  }
  get houseVisit() {
    if (this.house) return new House().assign(this.house)
    if (this.visit) return new House().assign(this.visit.house)
    return null
  }
  get visitTypes() {
    return this.generateEntries("visit_type")
  }
  get visitStatusText() {
    return translate("status", this.form.status).toLowerCase()
  }
  get confirmButtonLabel() {
    return this.visit?.id ? "Modifier la visite" : "Créer la visite"
  }
  get nextIntervalMinutes() {
    const now = new Date(Date.now())
    return (Math.floor(now.getMinutes() / this.interval) + 1) * this.interval
  }
  get dateStart() {
    if (this.form.start_at) {
      const date = new Date(this.form.start_at)
      return format(date, "yyyy-MM-dd")
    }
    return ""
  }
  set dateStart(newDateText: string) {
    const date = new Date(newDateText)
    if (this.form.start_at) {
      try {
        const start = new Date(this.form.start_at)
        start.setFullYear(date.getFullYear())
        start.setMonth(date.getMonth())
        start.setDate(date.getDate())
        this.form.start_at = start.toISOString()
      } catch (error) {
        throw new Error(
          `Cannot fetch '${this.constructor.name}'. ${error.message}`
        )
      }
    }
  }
  get timeStart() {
    if (this.form.start_at) {
      const date = new Date(this.form.start_at)
      return format(date, "HH:mm")
    }
    return ""
  }
  set timeStart(newTimeText: string) {
    const hours = parseInt(newTimeText.split(":")[0])
    const minutes = parseInt(newTimeText.split(":")[1])
    if (this.form.start_at) {
      const start = new Date(this.form.start_at)
      start.setHours(hours)
      start.setMinutes(minutes)
      start.setSeconds(0)
      start.setMilliseconds(0)
      this.form.start_at = start.toISOString()
      this.$set(this.form, "start", this.form.start_at)
    }
  }
  get promptCancelVisit() {
    return this.$refs.promptCancelVisit as PromptCancelVisit
  }
  get promptVisitSurveyModal() {
    return this.$refs.surveyModal as VisitSurveyModal
  }
  get gotHouseContext() {
    const { name } = this.$route
    if (name) {
      const routeNameComponents = name.split("/")
      return (
        routeNameComponents.findIndex((component) => component === "house") > -1
      )
    }
    return false
  }
  get inviteToSurvey() {
    return (
      this.form &&
      this.form.status === "planned" &&
      this.visitHasBegun &&
      this.ownByCurrentUser &&
      this.form.id
    )
  }
  get ownByCurrentUser() {
    return this.form.owner.id === state.profile.id
  }
  get visitHasBegun() {
    if (this.form.start_at) {
      const startDate = new Date(this.form.start_at)
      const nowDate = new Date()
      return startDate.getTime() < nowDate.getTime()
    }
    return false
  }

  mounted() {
    window.scrollTo(0, 0)
    this.initializeVisit()
  }

  newPendingChange() {
    this.gotPendingChange = true
  }

  async handlePendingChange(to: Route, from: Route, next: () => void) {
    // Determine if next route is still in the same 'house' context

    // Open prompt to ignore Pending message if applicable unless load next page
    const prompt = this.$refs
      .promptIgnorePendingMessage as PromptIgnorePendingForm
    if (this.gotPendingChange) prompt.open(next)
    else next()
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    await this.handlePendingChange(to, from, next)
  }

  async beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    await this.handlePendingChange(to, from, next)
  }

  @Watch("visitId")
  @Watch("house.visits")
  initializeVisit() {
    if (this.house?.visits && this.visitId && this.visitId >= 0) {
      let currentVisitIndex = this.house?.visits.findIndex(
        (houseVisit) => houseVisit.id == this.visitId
      )
      if (currentVisitIndex >= 0) {
        this.visit = this.house.visits[currentVisitIndex]
        this.visit.house = this.house
        this.form = new Visit(this.visit)
      }
    } else this.initializeNewVisit()
    this.currentCalendarDate = this.form.start_at || ""

    if (this.house?.visits) this.loading = false
  }

  agentUpdated(agent: Agent | null) {
    this.newPendingChange()
    if (agent) {
      this.form.agent = agent
      this.form.agent_id = agent.id
      this.form.agency_id = agent.agency_id
    } else {
      this.form.agency = null
      this.form.agency_id = null
      this.form.agent = null
      this.form.agent_id = null
    }
  }

  currentCalendarDateChanged(newCurrentDate: string) {
    this.currentCalendarDate = newCurrentDate
  }

  async initializeNewVisit() {
    if (this.house && this.$route.params.visitId === "new") {
      this.form = new Visit()
      const houseId = parseInt(this.$route.params.houseId)
      this.form.deal_id = parseInt(this.$route.params.dealId)
      if (houseId <= 0) return

      // Initialize house
      if (this.house && this.form.house) {
        this.form.house.id = this.house.id
        this.form.title = `Visite ${
          this.house.address || this.house.formatted_city_name || ""
        } - ${this.house.living_area} m²`
      }

      this.form.visit_type = "initial"

      // Initialize start_at with
      // DateTime
      const startDate = new Date()
      startDate.setMinutes(this.nextIntervalMinutes)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)
      this.form.start_at = startDate.toISOString()

      // Initialize visit location to house.address by default
      this.form.location = this.house?.address || null
    }
  }

  placeChanged(location: Address) {
    this.newPendingChange()
    this.form.location = location.fullFormattedAddress
  }

  generateEntries(key: string) {
    return Object.entries(getEnumValues(key)).map(([value, text]) => ({
      value,
      text,
    }))
  }
  customEmailsUpdated(emails: string[]) {
    this.form.guests = emails
  }
  homeCatchersUpdated(homecatchers: Profile[]) {
    this.form.home_catcher_guests = homecatchers
  }
  partnersUpdated(partners: Partner[]) {
    this.form.partners = partners
  }
  cancelVisit() {
    this.promptCancelVisit.open()
  }
  convertToDoneVisit() {
    if (this.visit) this.promptVisitSurveyModal.open(this.visit)
  }
  cancelVisitConfirm(newVisit: Visit) {
    this.saveVisitApi(newVisit, "La visite a correctement été annulée", true)
  }
  updateStatus(visit: Visit, nextState: VisitStatus) {
    visit.status = nextState
    this.saveVisitApi(visit, "La visite a correctement été terminée", false)
  }

  async saveVisit() {
    if (this.valid) {
      if (this.form.partners && this.form.partners.length)
        this.form.partner_ids = this.form.partners.map((partner) => partner.id)
      else this.form.partner_ids = []

      if (this.form.home_catcher_guests && this.form.home_catcher_guests) {
        this.form.home_catchers = this.form.home_catcher_guests.map(
          (hc) => hc.id
        )
      }
      this.saveVisitApi(
        this.form,
        "La visite a correctement été programmée",
        true
      )
    }
  }

  async saveVisitApi(
    visit: Visit,
    successMessage: string,
    shouldGoToVisitList: boolean
  ) {
    await visit.save().then((response) => {
      visit.assign(response)
      this.gotPendingChange = false
      EventBus.emit("reloadCurrentDeal")
      snackbar({
        color: "success",
        content: `${successMessage}`,
      })
      this.updateHouseVisit(visit)
      if (visit.house.id && shouldGoToVisitList)
        this.goToVisitList(visit.house.id)
    })
  }

  updateHouseVisit(visit: Visit) {
    if (this.house?.visits) {
      let currentVisitIndex = this.house?.visits.findIndex(
        (houseVisit) => houseVisit.id == visit.id
      )
      if (currentVisitIndex >= 0) {
        this.house.visits[currentVisitIndex] = new Visit(visit)
      } else {
        this.house.visits.push(new Visit(visit))
      }
    }
  }

  goToVisitList(houseId: number) {
    document.documentElement.scrollTop = 0
    if (this.$route.name) {
      const urlComponents = this.$route.name.split("/")
      this.$router.push({
        name: `${urlComponents[0]}/house/visits`,
        params: {
          houseId: houseId.toString(),
        },
      })
    }
  }

  // Form validators
  notEmpty = (value: string | number | null | undefined) =>
    typeof value == "number"
      ? true
      : !!(value && value.length) || "Champ requis"

  gotAgent(value: Agent | null) {
    return !!value
  }
}
