




















import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import { autocompletePlace, geocodePlaceId } from "~/plugins/places"

export type Address = {
  address: string
  zipcode: string
  cityName: string
  country: string
  fullFormattedAddress: string
  latitude: number
  longitude: number
}

@Component({
  components: {},
})
export default class VisitDetails extends Vue {
  @Prop({ required: false, default: null }) placeholder!: string
  @Prop({ required: false, default: null }) value!: Address
  placeId = ""
  searchAddress = ""
  predictedPlaces: google.maps.places.AutocompletePrediction[] = []

  @Watch("searchAddress")
  async fetchAddress(value: string | null | undefined) {
    if (!value) {
      this.predictedPlaces = []
      return
    }
    const results = await autocompletePlace(value)
    if (results) this.predictedPlaces = results
  }

  async addressSelected() {
    if (!this.placeId) {
      this.$emit("input", null)
      return
    }
    const details = await geocodePlaceId(this.placeId)

    // Data from geocoder extraction
    const streetNumber = this.extractGeocoderData(
      details.address_components,
      "street_number"
    )
    const route = this.extractGeocoderData(details.address_components, "route")
    const postalCode = this.extractGeocoderData(
      details.address_components,
      "postal_code"
    )
    const cityName = this.extractGeocoderData(
      details.address_components,
      "locality"
    )
    const country = this.extractGeocoderData(
      details.address_components,
      "country"
    )

    // Address Formatting
    let formattedAddress = ""
    if (streetNumber) formattedAddress += `${streetNumber}, `
    formattedAddress += route

    const address = {
      address: formattedAddress,
      cityName: cityName,
      country: country,
      zipcode: postalCode,
      fullFormattedAddress: details.formatted_address,
      latitude: details.latitude,
      longitude: details.longitude,
    }
    this.$emit("input", address)
  }

  extractGeocoderData(
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string
  ) {
    const data = addressComponents.find((component) =>
      component.types.find((t) => t === type)
    )
    return data?.long_name
  }
}
