


























import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Agent as AlgoliaAgent, agents, Hit } from "~/config/algolia"
import Agent from "~/models/Agent"
import OcularAvatar from "~/components/OcularAvatar.vue"
import AgentItem from "./AgentItem.vue"
import House from "~/models/House"

@Component({
  components: {
    OcularAvatar,
    AgentItem,
  },
})
export default class AgentSelect extends Vue {
  @Prop({ required: false, default: null }) placeholder!: string
  @Prop({ required: false, default: [] }) agencyIds!: number[]
  @Prop({ required: false, default: null }) house!: House | null
  @Prop({ required: false, default: [] }) rules!: []
  @Prop({ required: false, default: null }) value!: number | null
  inputValue: string | null = ""
  agents: Agent[] = []

  get agent() {
    return this.agents.find((agent) => agent.id === this.value)
  }

  mounted() {
    this.searchAgents()
  }

  @Watch("inputValue")
  @Watch("agencyIds")
  async searchAgents() {
    const filters = []
    if (this.agencyIds) {
      filters.push(
        this.agencyIds.map((id) => `current_agency_id = ${id}`).join(" OR ")
      )
    }
    if (this.house) {
      // First push the agents attached to the house
      const houseAgentsIds: number[] = []
      this.house.agencies?.forEach((agency) => {
        agency.agents?.forEach((agent) => {
          if (agent.id) {
            houseAgentsIds.push(agent.id)
          }
        })
      })
      if (houseAgentsIds.length > 0) {
        const response = await agents.search(this.inputValue || "", {
          filters: houseAgentsIds.map((id) => `id = ${id}`).join(" OR "),
        })
        const hits = response.hits as Hit<AlgoliaAgent>[]
        hits
          .map((hit) => Agent.fromAlgoliaHit(hit))
          .forEach((agent) => {
            if (this.agents.indexOf(agent) === -1) this.agents.push(agent)
          })

        // Then add other agents of agencies not linked yet
        filters.push(houseAgentsIds.map((id) => `id != ${id}`).join(" AND "))
      }
    }

    const options = {
      filters: filters.join(" AND "),
    }
    const response = await agents.search(this.inputValue || "", options)
    const hits = response.hits as Hit<AlgoliaAgent>[]
    hits
      .map((hit) => Agent.fromAlgoliaHit(hit))
      .forEach((agent) => {
        if (this.agents.indexOf(agent) === -1) this.agents.push(agent)
      })
  }

  selectAgent(agent: Agent | null | undefined) {
    if (!agent) this.$emit("input", null)
    else this.$emit("input", agent)
  }
}
