





















import { Vue, Component, Prop } from "vue-property-decorator"
import { format } from "date-fns"

const INTERVAL = 15

@Component({
  components: {},
})
export default class TimeIntervalSelection extends Vue {
  @Prop({ required: false, default: null }) startDate!: string
  @Prop({ required: false, default: 15 }) interval!: number
  @Prop({ required: false, default: null }) value!: string
  @Prop({ required: false, default: [] }) rules!: Array<() => boolean>

  /**
   * Generate the duration items
   */
  get durationItems() {
    const durations = []
    if (this.startDate) {
      const startDate = new Date(this.startDate)
      const start = INTERVAL
      const max = 180

      for (
        let duration = start;
        duration <= max;
        duration = duration + INTERVAL
      ) {
        const shift = duration * 60000
        const endTime = new Date(startDate.getTime() + shift)
        const interval_hours = this.formatTimePart(
          Math.floor(duration / 60).toString()
        )
        const interval_minutes = this.formatTimePart((duration % 60).toString())
        const item = {
          value: endTime.toISOString(),
          text: `${format(
            endTime,
            "HH:mm"
          )} (${interval_hours}h${interval_minutes})`,
        }
        durations.push(item)
      }
    }
    return durations
  }

  formatTimePart(part: string) {
    return part.padStart(2, "0")
  }

  input(newValue: string) {
    this.$emit("input", newValue)
  }

  change(newValue: string) {
    this.$emit("change", newValue)
  }
}
