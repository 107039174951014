import Collection from "~/jrdm-mc/Collection"
import CalendarEvent from "~/models/CalendarEvent"

export default class CalendarEvents extends Collection<CalendarEvent> {
  static route = "profile/calendar-events"
  static responseField = "events"
  model() {
    return CalendarEvent
  }

  async fetchByDate(start: string, end: string) {
    const options = {
      params: {
        time_min: start,
        time_max: end,
      },
    }
    return super.fetch(options)
  }
}
