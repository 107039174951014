


















import { Component, Vue } from "vue-property-decorator"
import OcularPrompt from "~/components/OcularPrompt.vue"
import { state } from "@/store"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptIgnorePendingForm extends Vue {
  callback: (() => void) | null = null

  open(callback: (() => void) | null) {
    this.callback = callback
    // @ts-ignore
    const dialog = this.$refs.dialog as OcularPrompt
    dialog.open = true
  }

  confirm() {
    state.currentMessage = ""
    if (this.callback) {
      this.callback()
    }
  }

  cancel() {
    this.$emit("cancel")
  }
}
