











































import { Vue, Component, Watch, Prop } from "vue-property-decorator"
import Column from "@/components/visits/Board/Columns/Column.vue"
import { format } from "date-fns"
import CalendarEvents from "~/collections/CalendarEvents"

type VuetifyCalendarEvent = {
  name: string | null
  start: number
  end: number
  timed: boolean
  color: string
  visitId: number | null
}

@Component({
  components: { Column },
})
export default class VisitCalendar extends Vue {
  events: VuetifyCalendarEvent[] = []
  ready = false
  loading = false
  @Prop({ required: false, default: "" }) value!: string

  get today() {
    return format(new Date(), "yyyy-MM-dd HH:mm:ss")
  }

  get calendar() {
    return this.ready ? (this.$refs.calendar as HTMLElement) : null
  }

  get nowY() {
    return this.calendar
      ? // @ts-ignore
        this.calendar.timeToY(this.calendar.times.now) + "px"
      : "-10px"
  }

  async mounted() {
    await this.fetchEvents()
    this.ready = true
    this.loading = true
  }

  // Event generation
  @Watch("startDate")
  @Watch("endDate")
  async fetchEvents() {
    if (this.startDate && this.endDate) {
      this.loading = true
      const calendarEvents = new CalendarEvents()
      await calendarEvents.fetchByDate(this.startDate, this.endDate)
      this.events = calendarEvents.items.reduce(
        (prev: VuetifyCalendarEvent[], event) => {
          if (event.start_at && event.end_at) {
            prev.push({
              name: event.summary,
              start: new Date(event.start_at).getTime()
                ? new Date(event.start_at).getTime()
                : 0,
              end: new Date(event.end_at).getTime()
                ? new Date(event.end_at).getTime()
                : 0,
              timed: true,
              color: event.visit_id ? "primary" : "gray",
              visitId: event.visit_id,
            })
          }
          return prev
        },
        []
      )
      this.loading = false
    }
  }
  getEventColor(event: VuetifyCalendarEvent) {
    return event.color
  }

  get startDate() {
    // @ts-ignore
    if (this.calendar) return this.calendar.lastStart.date
    return null
  }
  get endDate() {
    // @ts-ignore
    if (this.calendar?.lastEnd) {
      // @ts-ignore
      const date = new Date(this.calendar.lastEnd.date)
      date.setDate(date.getDate() + 1)
      return date.toDateString()
    }
    return null
  }

  // Day navigation
  setToday() {
    this.$emit("valueChanged", "")
  }
  prev() {
    // @ts-ignore
    this.$refs.calendar.prev()
  }
  next() {
    // @ts-ignore
    this.$refs.calendar.next()
  }

  // Events actions
  redirectToVisitDetails({ event }: { event: VuetifyCalendarEvent }) {
    if (event.visitId) {
      this.$router.push(`${event.visitId}`)
    }
  }
}
