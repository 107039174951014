






























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Partner from "~/models/Partner"
import Profile from "~/models/Profile"
import { state } from "@/store"
import { isValidEmail } from "~/utils/validate"
import OcularAvatar from "../OcularAvatar.vue"

type Item = {
  header: string | null
  object: any | null
  text: string | null
  group: string | null
  object_id: any | null
}

@Component({
  components: {
    OcularAvatar,
  },
})
export default class VisitGuestSelect extends Vue {
  @Prop({ required: false, default: () => [] }) selectionCustomEmails!: string[]
  @Prop({ required: false, default: () => [] }) selectionPartners!: Partner[]
  @Prop({ required: false, default: () => [] })
  selectionHomecatchers!: Profile[]
  selection: Item[] = []
  customEmails: string[] = []
  loading = true
  search: string | null = null

  async mounted() {
    this.customEmails = this.selectionCustomEmails
    this.selectionCustomEmails.forEach((email) => {
      this.selection.push(this.createCustomItem(email))
    })
    this.selectionPartners.forEach((partner) => {
      this.selection.push(this.createPartnerItem(partner))
    })
    this.selectionHomecatchers.forEach((hc) => {
      this.selection.push(this.createHomeCatcherItem(hc))
    })
    await this.fetchPartners()
  }

  /* Items factory */
  createCustomItem(email: string) {
    return {
      header: null,
      object: email,
      text: email,
      group: "Custom",
      object_id: email,
    } as Item
  }
  createPartnerItem(partner: Partner) {
    return {
      header: null,
      object: partner,
      text: `${partner.fullname} - ${partner.company?.name}`,
      group: "Partner",
      object_id: partner.id,
    } as Item
  }
  createHomeCatcherItem(salespeople: Profile) {
    return {
      header: null,
      object: salespeople,
      object_id: salespeople.id,
      text: salespeople.fullname,
      group: "Homecatcher",
    } as Item
  }

  async fetchPartners() {
    if (!state.partners.loaded) {
      state.partners.fetch()
    }
  }

  get partners() {
    if (state.partners) {
      return state.partners?.items
    }
    return []
  }
  get homeCatchers() {
    if (state.salespeople) {
      return state.salespeople.items.filter(
        (salesperson: Profile) =>
          salesperson.level == "homecatcher" && salesperson.discarded_at == null
      )
    }
    return []
  }
  /* Select items generation */
  get homeCatchersItems() {
    let items = []
    if (this.homeCatchers.length) {
      items.push({
        header: "Homecatchers",
      } as Item)
    }
    this.homeCatchers.forEach((salespeople: Profile) => {
      items.push(this.createHomeCatcherItem(salespeople))
    })
    return items
  }
  get partnersItems() {
    let items = []
    if (this.partners.length) {
      items.push({
        header: "Partenaires",
      } as Item)
    }
    this.partners.forEach((partner: Partner) => {
      items.push(this.createPartnerItem(partner))
    })
    return items
  }
  get customEmailItems() {
    let items = []
    if (this.customEmails.length) {
      items.push({
        header: "Emails",
      } as Item)
    }
    this.customEmails.forEach((email: string) => {
      items.push(this.createCustomItem(email))
    })
    return items
  }
  /* Generate selectable items */
  get items() {
    return [
      ...this.customEmailItems,
      ...this.partnersItems,
      ...this.homeCatchersItems,
    ] as Item[]
  }

  @Watch("selection")
  synchronizeSelection() {
    /* Emit new partners selection */
    const selectedPartners = this.selection
      .filter((selectionItem) => selectionItem.group === "Partner")
      .map((partnerItem) => partnerItem.object)
    this.$emit("update-partners", selectedPartners)

    /* Emit new homecatchers selection */
    const selectedHomeCatchers = this.selection
      .filter((selectionItem) => selectionItem.group === "Homecatcher")
      .map((hcItem) => hcItem.object)
    this.$emit("update-homecatchers", selectedHomeCatchers)

    /* Emit new custom email selection */
    const selectedCustomEmails = this.selection
      .filter((selectionItem) => selectionItem.group === "Custom")
      .map((customItem) => customItem.object)
    this.$emit("update-custom-emails", selectedCustomEmails)
  }

  isValidEmail(email: string) {
    return isValidEmail(email)
  }
  selectCurrentCustomEmail() {
    if (this.search) {
      this.customEmails = [...this.customEmails, this.search]
      this.selection.push(this.createCustomItem(this.search))
      this.search = null
    }
  }
  customFilter(item: Item, queryText: string) {
    if (item.header) return true
    const compared = item.text?.toLowerCase().replace(/[^a-zA-Z]/g, " ")
    const comparing = queryText.toLowerCase().replace(/[^a-zA-Z]/g, " ")
    if (compared && comparing) {
      return compared.indexOf(comparing) > -1
    }
    return null
  }
  unselect(item: Item) {
    this.selection = this.selection.filter((selectionItem) => {
      return !(
        selectionItem.object_id === item.object_id &&
        selectionItem.group === item.group
      )
    })
  }
}
